<template>
    <div v-if="status != 'deleted'" class="col">
        <div class="card h-100" :class="{'border-danger': status === 'error'}">
            <div class="card-body">
                <div v-if="status === 'editing'">
                    <input type="text" v-model="title" class="form-control mb-2" />
                    <textarea v-model="content" class="form-control"></textarea>
                </div>
                <div v-else>
                    <h5 class="card-title">{{ title }}</h5>
                    <p class="card-text" v-html="formattedContent"></p>
                </div>
                <p class="card-text text-muted">{{ noteCreatedAt }}</p>
            </div>
            <div class="card-footer d-flex justify-content-between align-items-center">
                <div v-if="status === 'saved'">Saved</div>
                &nbsp;
                <div>
                    <button @click="deleteNote" class="btn btn-sm btn-danger">
                        <i class="fa fa-trash"></i> Delete
                    </button>
                      <button v-if="status !== 'editing'" @click="editNote" class="btn btn-sm btn-primary">
                        <i class="fa fa-edit"></i> Edit
                    </button>
                    <button v-if="status === 'editing'" @click="saveNote" class="btn btn-sm btn-success">
                        <i class="fa fa-save"></i> Save
                    </button>
                    <button v-if="status === 'editing'" @click="cancelEdit" class="btn btn-sm btn-secondary">
                        <i class="fa fa-times"></i> Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
    import axios from 'axios';

    export default {
        name: "NoteCard",
        props: {
            noteId: {
                type: Number,
                required: true
            },
            noteTitle: {
                type: String,
                required: true
            },
            noteContent: {
                type: String,
                required: true
            },
            noteCreatedAt: {
                type: String,
                required: true
            },
            deleteUrl: {
                type: String,
                required: true
            },
            updateUrl: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                status: "init",
                title: this.noteTitle,
                content: this.noteContent,
                originalTitle: this.noteTitle,
                originalContent: this.noteContent
            };
        },
        computed: {
            formattedContent() {
                // Use a regular expression to find URLs and replace them with HTML anchor tags
                return this.content.replace(
                    /(https?:\/\/[^\s]+)/g,
                    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
                );
            },
        },
        methods: {
            deleteNote() {
                if (confirm('Are you sure you want to delete this note?')) {
                    axios.delete(this.deleteUrl)
                        .then(() => {
                            this.status = "deleted";
                        })
                        .catch(error => {
                            this.status = "error";
                            console.error('There was an error deleting the note:', error);
                        });
                }
            },
            editNote() {
                this.status = "editing";
            },
            saveNote() {
                axios.put(this.updateUrl, {
                    title: this.title,
                    content: this.content
                })
                .then(() => {
                    this.status = "saved";
                })
                .catch(error => {
                    this.status = "error";
                    console.error('There was an error updating the note:', error);
                });
            },
            cancelEdit() {
                this.title = this.originalTitle;
                this.content = this.originalContent;
                this.status = "init";
            }
        },
        mounted: function () {
        }
    };
</script>
  
<style scoped>
    .card {
        margin-bottom: 1rem;
    }
</style>